import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="md:flex min-h-screen">
      <div className="w-full md:w-1/2 bg-white flex items-center justify-center ">
        <div className="max-w-sm m-8">
          <h1 className="text-black text-2xl md:text-4xl">NOT FOUND</h1>
          <div className="text-black text-3xl md:text-5xl font-black">404</div>
          <div className="w-16 h-1 bg-purple-light my-3 md:my-6"></div>
          <p className="text-grey-darker text-2xl md:text-3xl font-light mb-8 leading-normal">
            Xin lỗi, bài viết bạn đang tìm kiếm hiện không tồn tại.
          </p>
          <Link
            href="/"
            className="bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-grey-light hover:border-grey rounded-lg"
          >
            Về Trang chủ
          </Link>
        </div>
      </div>
      <div className="relative pb-full md:flex md:pb-0 md:min-h-screen w-full md:w-1/2">
        <div
          style={{
            backgroundImage: `url('/undraw_page_not_found_su7k.png')`,
          }}
          className="absolute top-0 bottom-0 left-0 right-0 bg-contain bg-no-repeat md:bg-left lg:bg-center"
        ></div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
